export const booking = {
    common: {
        timer: {
            processing:'The booking will expire in 20 minutes and the process will need to be repeated.',
            over: 'The booking has expired',
            title: 'Booking',
        },
        info_titles: {
            location: 'Location',
            floor: 'Floor',
            finishing: 'Finishing',
            apartment_price: 'The cost of the realty',
            booking_price: 'The cost of booking',
            purchaseTerms: 'Terms of purchase',
            booking_period: 'Booking period',
            booking_start: 'Booking date',
            booking_end: 'End date',
            booking_rate: 'Booking rate',
            realty_price_short: 'Realty price',
            payment_method: 'Payment method',
            rooms: 'Rooms',
            withFinishing: 'With finishing',
            withoutFinishing: 'Without finishing',
        },
        navigation: {
            objects: "Objects",
        },
        statuses: {
            client: {
                in_process: 'In process',
                booked_until: 'Booked until',
                approved: 'Approved',
                awaiting_confirmation: 'Awaiting confirmation',
                expired: 'Not completed',
                cancelled: 'The contract has not been signed',
                refusal: 'Refusal',
                deal: 'Contract',
            },
            tooltips: {
                wait: 'The booking order has been accepted. The funds on the card are blocked; they will be debited after the booking is confirmed by the manager.',
                refusal: 'The object is unavailable. The money will be returned to the card. We will help you choose a new one.',
            }
        },
    },
    client: {
        purchase_conditions: {
            title: 'Choose a purchase method',
            tooltip: 'Step 1 - Choosing a purchase method',
            text: 'For your convenience, we offer several options for purchasing real estate. Please choose the payment method that suits you best:',
            types: {
                mortgage: 'Mortgage starting from',
                mortgage_short: 'Mortgage',
                installment: 'Installment starting from',
                installment_short: 'Installment',
                immediate: 'Full payment'
            },
            items: {
                initialPayment: 'Initial payment',
                cost: 'Cost',
                creditPeriod: 'Credit Period',
                rate: 'Rate',
                bookingpPeriod: 'Booking period',
            },
            mortgage: {
                types: {
                    arctic: 'Arctic mortgage',
                    family: 'Family mortgage',
                    far_east: 'Far eastern mortgage',
                    government_support: 'Government support',
                    it: 'Mortgage for IT specialists',
                    military: 'Military mortgage',
                    standart: 'Standard mortgage',
                    village: 'Rural mortgage'
                },
                filter: {
                    select_program: 'Select program',
                    initial_payment_percent: 'Initial payment',
                    period_in_years: 'Term'
                },
                list: {
                    card: {
                        initial_payment_percent: 'Initial payment',
                        rate: 'Rate',
                        period: 'Term',
                        credit_amount: 'Loan amount'
                    },
                    empty: 'The list of mortgage programs is currently empty',
                    emptyDescription: 'Try using different filters',
                },
                validation: {
                    select_program: 'Select program',
                }
            },
        },
        personal_data: {
            title: 'Enter Personal Information',
            tooltip: 'Step 2 - Entering Personal Data',
            text: 'To complete the booking, we need your information and an email address to send the confirmation. Make sure to provide the details of the future apartment owner.',
            form: {
                emailInfo: 'A booking confirmation will be sent to this email',
            },
        },
        offer: {
            title: 'Offer Agreement',
            tooltip: 'Step 3 - Acceptance of the Offer Agreement',
        },
        payment: {
            title: 'Payment',
            tooltip: 'Step 4 - Payment',
        },
        result: {
            success: {
                title: 'The payment was successful',
                text: 'To find your reservation, go to your personal account and select the "Reservations" tab.',
                button: 'Go to your personal account',
            },
            fail: {
                title: 'Payment error',
                text: 'Unfortunately, the payment failed. Try another payment method or contact support.',
                button: 'Repeat the payment',
            },
            undefined: {
                title: 'Something went wrong',
                button: 'Go to your personal account',
            }
        },
        bookings: {
            title: 'Your booking',
            list: {
                empty: 'The list of objects is currently empty',
                emptyDescription: 'After the purchase, all your objects will appear here',
                toRealtyCatalog: 'Go to the catalog',
            },
            button: {
                information: 'Information',
                continue: 'To booking',
                awaiting_confirm: 'Awaiting confirmation',
                mortgage: 'Mortgage Application',
                pay: 'Pay the entire cost',
            },
            tooManyRequests: {
                title: 'Complete the booking',
                description: 'You already have two incomplete bookings. To create a new one, please complete one of the current bookings: pay for it or wait for the booking time to expire.',
                toList: 'Go to the list of bookings'
            },
            alreadyBooking: {
                title: 'Return to booking',
                description: 'Your reservation is in the process of being processed. Complete it to ensure your chosen property is reserved.',
                cardTitle: 'Booking options',
                term: 'Term',
                price: 'Booking price',
                continue: 'Continue booking'
            }
        },
        summary: {
            title: 'Your booking',
            info: {
                booking: 'Booking',
                location: 'Location',
                realty_info: 'Information about the object',
                documents: 'Documents',
                titles: {
                    booking_price: 'The cost of booking',
                    term: 'Term',
                },
            },
            download_offer: 'Offer agreement',
        },
        common: {
            time_is_up: {
                title: 'Time is up',
                scription: 'The allotted time for booking has expired. You will be redirected to the booking page.'
            }
        },
        dialog_continue: {
            title: 'Return to booking',
            description: 'Your booking is in the process of being completed. Finish it to guarantee the reservation of the item you selected.',
            parameters: 'Parameters of booking',
            term: 'Term',
            price: 'Booking price',
            continue: 'Continue booking',
        }
    },
    admin: {
        registry: {
            title: 'Bookings',
            headers: {
                client: 'Client',
                realty: 'Realty',
                tariff: 'Tariff',
                price: 'Price',
                method: 'Method',
                end_date: 'Period up to',
                status: 'Status',
                contract: 'Contract',
            },
            dialog: {
                titles: {
                    contract: 'Contract number',
                    buyer: 'Information about the client',
                },
                placeholders: {
                    status: 'Select the status',
                    contract: 'Enter the contract number',
                },
                error: 'Enter the contract number',
                confirm: {
                    title: 'confirm the action',
                    text: {
                        approved: 'After confirmation of the action, the amount for the booking will be debited from the clients account.',
                        refusal: 'After confirmation of the action, the booking amount will be refunded to the customer.',
                    },
                    return: 'Go back to booking',
                },
            }
        },
    },
};
